.login-page .ui.form.login-form {
  margin: 0 auto;
  padding: 60px 0 40px 0;
  max-width: 400px;
}

.login-page div.login-selection {
  margin: 100px auto;
  max-width: 600px;
  text-align: center;
}

.login-page div.login-selection>.ui.header {
  text-shadow: 0px 0px 10px white, 0px 0px 10px white, 0px 0px 10px white;
}

.login-page div.login-selection .selection-button-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-page div.login-selection .selection-button {
  width: 45%;
}

.login-page div.login-selection .divider {
  font-size: 1.2rem;
  font-weight: bold;
}

.login-page div.login-selection .selection-button .ui.segment {
  background-color: rgba(255, 255, 255, .95);
  cursor: pointer;
}

.login-page div.login-selection .selection-button:hover .ui.segment,
.login-page div.login-selection .selection-button:focus .ui.segment {
  background-color: rgba(245, 245, 245, 0.95);
}

.login-page div.login-selection .selection-button:focus .ui.segment {
  box-shadow: 0 0 2px 1px rgba(24, 175, 245, 0.582);
}

.login-page div.login-selection .selection-button:hover,
.login-page div.login-selection .selection-button:focus {
  outline: none;
}

.login-page .about-us-container {
  text-align: center;
  cursor: pointer;
}

.login-page .about-us-container .ui.label {
  color: #c04a28!important;
  border-color: #c04a28!important;
}

.login-page div.ui.message {
  margin: 0 auto;
  max-width: 400px;
}

.login-page div.sso-container {
  margin: 20px auto;
  max-width: 250px;
}

.login-page div.google-sso-container {
  margin: 20px auto;
  max-width: 183px;
  display: none;
}

.login-page div.sso-container button {
  cursor: pointer;
}

.login-page button.ui.fluid.image {
  padding: 0;
  border-width: 0;
}

.login-page a {
  cursor: pointer;
}

.login-page {
  background-image: url("https://course.base.education/img/teen-with-backpack-on-top-of-mountain.jpg");
  background-position: left center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow-y: auto;
  min-height: 100vh;
}