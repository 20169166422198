.reset-password-form {
  margin: 0 auto;
  padding: 100px 0 40px 0;
  max-width: 400px;
}

.reset-password-page div.ui.message {
  margin: 0 auto;
  max-width: 400px;
}

.reset-password-page a {
  cursor: pointer;
}

.reset-password-page {
  background-image: url("https://course.base.education/img/teen-with-backpack-on-top-of-mountain.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow-y: auto;
  min-height: 100vh;
}